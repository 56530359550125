<template>
  <div class="kj_science">
    <div class="science_bg" v-if="Object.keys(basicData).length != 0">
      <img :src="basicData.basicImg" alt="" />
    </div>
    <ul class="science_up" v-if="Object.keys(basicData).length != 0">
      <li
        v-for="(item, idx) in basicData.basicList2"
        :key="idx"
        @click="toPosition(idx + 1)"
      >
        <a @mouseenter="active = idx" @mouseleave="active = -1">
          <img :src="item.key" alt="" style="width:100%" v-if="active != idx" />
          <img
            v-else
            :src="basicData.basicList[idx].value"
            alt=""
            style="width:100%"
          />
        </a>
        <div class="li_line"></div>
        <div class="li_item" v-if="active == idx">
          {{ item.value }}
        </div>
      </li>
    </ul>
    <div
      class="science_nbg"
      v-if="Object.keys(nrData).length != 0"
      :style="{ backgroundImage: `url(${nrData.nrBg[0]})` }"
    >
      <div class="science_nav">
        <div class="science_nav_1">
          <div class="nav_text">
            <div class="all_title">
              <div class="all_title_head">
                <span>01</span>
                <div class="head_line"></div>
              </div>
              <div class="all_title_text">
                <h2>{{ nrData.nrText[0].key }}</h2>
                <a href="">查看详情 >></a>
              </div>
            </div>
            <p>
              {{ nrData.nrText[0].value }}
            </p>
          </div>
          <div class="nav_img">
            <img :src="nrData.nrBg[1]" alt="" />
          </div>
        </div>
        <div class="science_nav_2 nav_top">
          <div class="all_title">
            <div class="all_title_head">
              <span>02</span>
              <div class="head_line"></div>
            </div>
            <div class="all_title_text">
              <h2>{{ nrData.nrText[1].key }}</h2>
              <a href="">查看详情 >></a>
            </div>
          </div>
          <div class="nav_img">
            <img :src="nrData.nrBg[2]" alt="" />
          </div>
        </div>
        <div class="science_nav_3 nav_top">
          <div class="all_title">
            <div class="all_title_text">
              <h2>{{ nrData.nrText[1].value }}</h2>
              <a href="">查看详情 >></a>
            </div>
            <div class="all_title_head">
              <span>03</span>
              <div class="head_line"></div>
            </div>
          </div>
          <ul class="nav_list">
            <li
              v-for="(item, idx) in zjList"
              :key="idx"
              :class="[
                active1 == idx ||
                active2 == idx ||
                active3 == idx ||
                active4 == idx
                  ? 'xz_li'
                  : ''
              ]"
            >
              <img :src="item.site_images" alt="" />
              <div class="li_mask"></div>
            </li>
          </ul>
        </div>
        <div class="science_nav_4">
          <div class="all_title">
            <div class="all_title_text">
              <h2>{{ nrData.nrText[2].key }}</h2>
              <a href="">查看详情 >></a>
            </div>
            <div class="all_title_head">
              <span>04</span>
              <div class="head_line"></div>
            </div>
          </div>
          <div class="nav_swiper" v-if="newsList.length != 0">
            <div class="nav_swiper_1">
              <el-carousel :interval="5000" height="500px">
                <el-carousel-item
                  v-for="(item, idx) in newsList[0].news"
                  :key="idx"
                >
                  <img :src="item.cover_image" alt="" />
                  <div class="news_nav">
                    <h2>
                      <span class="ellipsis">{{ item.title }}</span>
                      <span style="margin-left:20px;color: #666;">{{
                        item.create_time
                      }}</span>
                    </h2>
                    <p class="two_ellipsis">{{ item.miaoshu }}</p>
                    <a href="">
                      查看详情 >>
                    </a>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="nav_swiper_2">
              <el-carousel :interval="5000" height="500px">
                <el-carousel-item
                  v-for="(item, idx) in newsList[1].news"
                  :key="idx"
                >
                  <img :src="item.cover_image" alt="" />
                  <div class="news_nav">
                    <h2>
                      <span class="ellipsis">{{ item.title }}</span>
                      <span style="margin-left:20px;color: #939292;">{{
                        item.create_time
                      }}</span>
                    </h2>
                    <p class="two_ellipsis">{{ item.miaoshu }}</p>
                    <a href="">
                      查看详情 >>
                    </a>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
        <div class="science_nav_5">
          <div class="all_title">
            <div class="all_title_text">
              <h2>{{ nrData.nrText[2].value }}</h2>
              <a href="">查看详情 >></a>
            </div>
            <div class="all_title_head">
              <span>05</span>
              <div class="head_line"></div>
            </div>
          </div>
          <div class="nav_achieve" v-if="cgList.length != 0">
            <ul class="nav_achieve_left">
              <li v-for="(item, idx) in cgList" :key="idx">
                <a href="">
                  <img :src="item.site_images" alt="" />
                  <div class="box_content">
                    <h2 class="two_ellipsis">{{ item.title }}</h2>
                    <p class="two_ellipsis">{{ item.subtitle }}</p>
                  </div>
                </a>
              </li>
            </ul>
            <ul class="nav_achieve_right">
              <li>
                <a href="">
                  <img :src="cgList[2].site_images" alt="" />
                  <div class="box_content">
                    <h2 class="two_ellipsis">{{ cgList[2].title }}</h2>
                    <p class="two_ellipsis">{{ cgList[2].subtitle }}</p>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPageMsg,
  getBasicMsg,
  getJyCaseList,
  getXwData,
  getProductList
} from '@/api/api'
export default {
  data () {
    return {
      basicData: {},
      active: -1,
      nrData: {},
      zjList: [],
      timer: '',
      active1: -1,
      active2: -1,
      active3: -1,
      active4: -1,
      total: 0, // 总数
      newsList: [],
      cgList: []
    }
  },
  methods: {
    getData () {
      let that = this
      getBasicMsg(this.changeData()).then(item => {
        if (item.data.code == 1) {
          let id = item.data.data.menu_id
          let project = item.data.data.project_id
          getPageMsg(id).then(res => {
            if (res.data.code == 1) {
              that.basicData = res.data.data.basic
              that.nrData = res.data.data.内容
            }
          })
          getJyCaseList(1, 40, project).then(res => {
            if (res.data.code == 1) {
              that.zjList = res.data.data.list
              that.total = res.data.data.total
            }
          })
          getXwData(project).then(res => {
            if (res.data.code == 1) {
              that.newsList = res.data.data
            }
          })
          getProductList(1, '', project).then(res => {
            if (res.data.code == 1) {
              let arr = []
              res.data.data.list.forEach((el, i) => {
                if (i < 3) {
                  arr.push(el)
                }
              })
              that.cgList = arr
            }
          })
        }
      })
      that.timer = setInterval(that.valChange, 1000)
    },
    valChange () {
      this.active1 = Math.floor(Math.random() * this.zjList.length)
      this.active2 = Math.floor(Math.random() * this.zjList.length)
      this.active3 = Math.floor(Math.random() * this.zjList.length)
      this.active4 = Math.floor(Math.random() * this.zjList.length)
    },
    toPosition (i) {
      if (i != 6) {
        $('html,body').animate(
          { scrollTop: $('.science_nav_' + i).offset().top - 60 },
          500
        )
      } else {
        $('html,body').animate({ scrollTop: 0 }, 800)
      }
    },
    toLink (i) {
      sessionStorage.setItem(this.changeData() + 'id', i)
    }
  },
  mounted () {
    this.getData()
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.kj_science {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .science_bg {
    width: 100%;
    height: auto;
    img {
      width: 100%;
    }
  }
  .science_up {
    width: 50px;
    position: fixed;
    left: 5%;
    bottom: 5%;
    z-index: 9;
    li {
      position: relative;
      height: 70px;
      z-index: 10;
      a {
        cursor: pointer;
      }
      .li_line {
        position: absolute;
        height: 40px;
        width: 2px;
        margin: 0 auto;
        background-color: #666666;
        left: calc(50% - 1px);
        top: 35px;
        z-index: -1;
      }
      .li_item {
        position: absolute;
        top: calc(50% - 26px);
        left: 60px;
        padding: 10px;
        background-color: #4e307c;
        color: #fff;
        font-size: 12px;
        border-radius: 5px;
        white-space: nowrap;
      }
    }
    li:nth-child(6) .li_line {
      display: none !important;
    }
  }
  .science_nbg {
    height: 100%;
  }
  .science_nav {
    width: 1200px;
    margin: 0 auto;
    padding: 80px 0;
    .all_title {
      .all_title_head {
        display: flex;
        align-items: center;
        font-size: 48px;
        color: #432c7c;
        font-weight: bold;
        .head_line {
          height: 8px;
          margin-left: 20px;
          width: 150px;
          background-color: #432c7c;
        }
      }
      .all_title_text {
        margin-top: 10px;
        h2 {
          font-size: 24px;
          font-weight: 600;
        }
        a {
          color: #432c7c;
          font-size: 14px;
          display: flex;
          margin-top: 10px;
        }
      }
    }
    .nav_top {
      margin-top: 50px;
    }
    .science_nav_1 {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      .nav_text {
        max-width: 50%;
        p {
          font-size: 14px;
          text-indent: 2em;
          line-height: 2;
          margin-top: 20px;
        }
      }
      .nav_img {
        max-width: 50%;
        img {
          width: 100%;
        }
      }
    }
    .science_nav_2 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .all_title {
        max-width: 15%;
        .all_title_head {
          flex-direction: column;
          .head_line {
            height: 150px;
            width: 8px;
            margin-left: 0px;
            margin-top: 15px;
          }
        }
        .all_title_text {
          margin-top: 20px;
          margin-right: -40px;
        }
      }
      .nav_img {
        width: 86%;
        img {
          width: 100%;
        }
      }
    }
    .science_nav_3,
    .science_nav_4,
    .science_nav_5 {
      .all_title {
        display: flex;
        justify-content: flex-end;
        .all_title_text {
          margin-top: 0;
          margin-right: 40px;
        }
      }
      .nav_list {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        li {
          width: 10%;
          height: 140px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
          .li_mask {
            background-color: rgba(20, 20, 150, 0.6);
            text-align: center;
            width: 100%;
            height: 100%;
            transform: translateX(-50%) translateY(-50%) scale(1);
            position: absolute;
            left: 50%;
            top: 50%;
            z-index: 2;
          }
        }
        .xz_li .li_mask {
          display: none;
        }
      }
    }
    .science_nav_4,
    .science_nav_5 {
      margin-top: 50px;
      .nav_swiper {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        /deep/.el-carousel__indicators {
          display: none;
        }
        .nav_swiper_1,
        .nav_swiper_2 {
          width: 49%;
          img {
            width: 100%;
            height: calc(100% - 150px);
          }
          .news_nav {
            margin-top: 10px;
            h2 {
              display: flex;
              justify-content: space-between;
              span {
                white-space: nowrap;
              }
            }
            p {
              margin: 10px 0;
              font-size: 14px;
              line-height: 1.5;
              color: #666666;
            }
            a {
              display: block;
              font-size: 14px;
              color: #432c7c;
              font-weight: 600;
            }
          }
        }
      }
      .nav_achieve {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .nav_achieve_left,
        .nav_achieve_right {
          width: 30%;
          img {
            width: 100%;
          }
          li {
            margin-top: 20px;
            position: relative;
            .box_content {
              opacity: 0;
              transform: translateX(-50%) translateY(-50%);
              position: absolute;
              left: 50%;
              top: 50%;
              z-index: 2;
              transition: all 1s ease 0s;
              width: 90%;
              margin: 0 auto;
              line-height: 1.5;
              color: #fff;
              text-align: center;
              h2 {
                font-weight: 600;
              }
              p {
                font-size: 14px;
              }
            }
          }
          li:before {
            content: '';
            background: rgba(28, 37, 116, 0.5);
            opacity: 0;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 1;
          }
          li:hover:before {
            opacity: 1;
          }
          li:hover .box_content {
            opacity: 1;
          }
          li:nth-child(3) {
            display: none !important;
          }
        }
        .nav_achieve_right {
          width: 68%;
          li {
            height: calc(100% - 20px);
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
