import { render, staticRenderFns } from "./kh_science.vue?vue&type=template&id=13c63e75&scoped=true&"
import script from "./kh_science.vue?vue&type=script&lang=js&"
export * from "./kh_science.vue?vue&type=script&lang=js&"
import style0 from "./kh_science.vue?vue&type=style&index=0&id=13c63e75&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13c63e75",
  null
  
)

export default component.exports